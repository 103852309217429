.navlogo {
  width: 162px;
  height: auto;
  z-index: 501;
}

.navbar-nav {
  margin-top: -35px;
}

.fw-bold {
  font-weight: bold !important;
}

.upper {
  text-transform: uppercase;
}

.userspace {
  margin-left: 20px;
}

.nav-item {
  margin-top: 12px;
  color: white;
  font-weight: 500;
}

.allnav {
  z-index: 500;
}

.avatar {
  height: 35px;
  width: 35px;
  border-radius: 50px;
  box-shadow: 0 1px 3px 1px rgb(0 0 0 / 8%);
  background-color: #333;
  
  bottom: 5px;
  z-index: 1;
  background-position-x: center;
  background-position-y: center;
  background-repeat: no-repeat;
  background-size: cover;
  
}

#avatar {
  height: 60px;
  width: 60px;

  border-radius: 50px;
  box-shadow: 0 5px 5px 0 rgb(0 0 0 / 43%);
  background-color: #333;
  background-position-x: center;
  background-position-y: center;
  background-repeat: no-repeat;
  background-size: cover;
 
  border: 2px solid white;
  z-index: 1;
}



.icon {
  background-position-x: center;
  background-position-y: center;
  background-repeat: no-repeat;
  background-size: cover;
  margin-left: auto;
  margin-right: auto;
}

.icon-disabled {
  margin-left: auto;
  margin-right: auto;
}

.avatar-table {
  height: 45px;
  width: 45px;
  border-radius: 50px;
  box-shadow: 0 1px 3px 1px rgb(0 0 0 / 8%);
  background-color: #333;
  z-index: 1;
  background-position-x: center;
  background-position-y: center;
  background-repeat: no-repeat;
  background-size: cover;
  margin-top: 5px;
}

.navtop__bg {
  position: relative;
  top: -70px;
  bottom: 0;
  right: 0;
  left: 0;
  width: 100%;
  height: 200px;
  background-image: linear-gradient(#252525, #000000);
  /* transform: skewY(3deg); */
  transform-origin: top;
  z-index: 500;
  box-shadow: 0 2px 7px 1px #0000006b;
}

.navbar-brand {
  display: inline-block;
  padding-top: 0;
  padding-bottom: 0.3125rem;
  margin-right: 0rem;
  line-height: inherit;
  white-space: nowrap;
  /* padding-left: 20px; */
}

/* #burger {
} */

.topnav {
  z-index: 501;
  position: fixed;
  top: 0;
}

.menubtn:hover {
  box-shadow:none;
  flex: 0 0 75%;
  max-width: 75%;
}

.btn:hover {
 color:rgb(137, 137, 137)
}
/* .btn:focus {
  outline: 0;
  box-shadow: inset 2px -1px 2px #162b16, inset -2px 1px 3px #060606 !important;
} */

.btn:visited {
  outline: 0;
  box-shadow: inset 2px -1px 2px #162b16, inset -2px 1px 3px #060606 !important;
}

.btn:after {
  outline: 0;
  box-shadow: inset 2px -1px 2px #162b16, inset -2px 1px 3px #060606 !important;
}

.btn-black {
  background-color: #000;
}

.dawg {
  z-index: 500;
}

.card-title:hover {
  color: var(--primary-color);
}

.lowernav {
  position: fixed;
  right: 0;
  z-index: 501;
  top:0;
  padding-top: 10px;
  padding-right: 3%;
}

.username {
  position: relative;
  padding-right: 80px;
  z-index: 460;
  margin-top: 80px;
  font-weight: bold;
}


.btn-group-sm > .btn,
.btn-sm {
  line-height: 1;
}

.navbuttons {
  /* width: 360px; */
  z-index: 499;
}

.staffnav {
  margin-left: 5px;
}

.dirnav {
  margin-left: 20px;
}

.adnav {
  margin-left: 5px;
}

.right {
  right: 0;
}

.navlink {
  color: #fff;
}

.loglink {
  background-color:white;
  width:30px;
  height: 30px;
  
}


.nav-link2 {
  color: rgb(223, 219, 19) !important;
}

@media screen and (min-width: 1921px) {
  .navtop__bg {
    height: 180px;
    transform: skewY(2deg);
  }
  
  .dawg {
    zoom: 1;
  }
}

@media screen and (max-width: 1920px) {
  .navtop__bg {
    height: 175px;
    transform: skewY(2.5deg);
  }
  
  .dawg {
    zoom: 0.9;
  }
}

@media screen and (max-width: 1680px) {
  .navtop__bg {
    height: 170px;
    transform: skewY(3deg);
  }
  
  .dawg {
    zoom: 0.8;
  }
}

@media screen and (max-width: 1280px) {
  .navtop__bg {
    height: 170px;
    transform: skewY(3.5deg);
  }
  
  .dawg {
    zoom: 0.75;
  }
}

@media screen and (max-width: 996px) {
  .navtop__bg {
    height: 160px;
    transform: skewY(4deg);
  }
 
  .dawg {
    zoom: 0.7;
  }
}



@media screen and (max-width: 600px) {
  .navtop__bg {
    height: 170px;
    transform: skewY(4.5deg);
  }
  
 
  .dawg {
    zoom: 0.6;
  }
  .sizehide {
    display: none;
  }
  /* .navbuttons {
    zoom: 0.85;
    margin-left: 5px;
  } */
  .burger-button {
    bottom: 0px;
  }
  .dirnav {
    zoom: 0.85;
  }
  .adnav {
    zoom: 0.85;
  }
}
