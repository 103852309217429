/* .tab {
  overflow: hidden;
  border: 1px solid #ccc;
  background-color: #f1f1f1;
}

.tab button {
  background-color: inherit;
  float: left;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 14px 16px;
  transition: 0.3s;
}

.tab button:hover {
  background-color: #ddd;
}

.tab button.active {
  background-color: #ccc;
}

.tabcontent {
  display: none;
  padding: 6px 12px;
  border: 1px solid #ccc;
  border-top: none;
} */

#tab1,
#tab2,
#tab3 {
  display: none;
}
/* input {
  display: none;
} */

.override-input input {
  display: block;
}

input + label {
  display: inline-block;
}

input ~ .tab {
  display: none;
}
#tab1:checked ~ .tab.content1,
#tab2:checked ~ .tab.content2,
#tab3:checked ~ .tab.content3,
#tab4:checked ~ .tab.content4,
#tab5:checked ~ .tab.content5 {
  display: block;
}

input + label {
  /* border: 1px solid #999; */
  /* background: #eee; */
  /* padding: 4px 12px; */
  /* border-radius: 4px 4px 0 0; */
  position: relative;
  top: -10px;
}
input:checked + label {
  background: #fff;
  border-bottom: 1px solid transparent;
}
input ~ .tab {
  border-top: 1px solid #999;
  padding: 12px;
}

.borderline {
  border-bottom: 1ps solid #999;
  width: 100%;
}



.border5 {
  border: 1px solid #00000052;
  border-radius: 7px;
}

/* .borderbtm {
  border-bottom: 1px solid #00000052;
} */

.greybkg {
  background-color: rgb(60 60 60);
}
