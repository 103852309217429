th.filter {
    cursor: pointer;
}

th.filter:hover {
    background-color: gainsboro;
}

th.filter:focus {
    border:solid 1px 0 1px 0 rgb(0, 0, 0);
}
