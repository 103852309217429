/* .profheader {
  margin-top: -50px;
} */

.profile {
  min-height: 800px;
  display: content;
  padding-bottom: 5%;
}

.profile-bkg {
  max-width: 100vw;
  min-width: 100%;
  min-height: 320px;
  position: relative;
  background-size: cover;
  background-position: right;
  background-repeat: no-repeat;
  display: block;
  overflow: hidden;
}

.profile-sig {
  max-width: 20vw;
  min-width: 100px;
  min-height: 100px;
  position: relative;
  background-size: cover;
  background-position: right;
  background-repeat: no-repeat;
  display: block;
  overflow: hidden;
  margin-left: auto;
  margin-right: auto;
}

.upload-bkg {
  min-height: 150px;
  min-width: 280px;
  position: absolute;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  /* display: block; */
  overflow: hidden;
  margin-left: 30%;
  max-height: 200px;
  max-width: 600px;
}

.personal-info {
  margin: 0 20% 0 20%;
}

.card-header {
  border-radius: 0 0 10px 10px;
  text-align: center;
  padding: 0;
  margin-bottom: 0;
  /* margin-top: 10px; */
  /* background-color: rgba(255, 255, 255, 0.918);
  border-bottom: 0px solid rgba(0, 0, 0, 0.125); */
}

.bio-header {
  min-height: 5vh;
  max-height: 25vh;
  margin-top: 5%;
  border-radius: 10px 10px 0 0;
}

.img-container {
  width: 190px;
  height: 190px;
  overflow: hidden;
  display: contents;
  background-size: cover;
}

div.bpic {
  margin-top: 5%;
  margin-left: 5%;
  border-radius: 50%;
  border: 5px white solid;
  background-color: rgb(0, 0, 0);
  z-index: 1;
  position: absolute;
  background-position-x: center;
  background-position-y: center;
  background-repeat: no-repeat;
  background-size: cover;
  border: solid 3px #f3f3f3;
}

div.uppic {
  top: 0;
  margin-left: 30%;
  border-radius: 50%;
  border: 5px white solid;
  background-color: rgb(0, 0, 0);
  z-index: 1;
  position: absolute;
  background-position-x: center;
  background-position-y: center;
  background-repeat: no-repeat;
  background-size: cover;
  border: solid 3px #f3f3f3;
  width: 100px;
  height: 100px;
}

.title-row {
  display: flex;
  flex-wrap: wrap;
  border-radius: 0px 0px 7px 7px;
  border: none;
}

/* .profbkg {
  position: relative;
  border-radius: 7px 7px 0 0;
  border: solid 1px #f3f3f3;
} */

.proftitle {
  color: #fec61f;
  text-emphasis: none;
}
/* 20 */

/* .prof-avatar {
  margin-top: 5%;
  margin-left: 5%;
  border-radius: 50%;
  border: 5px white solid;
  background-color: rgb(31, 31, 31);
  z-index: 1;
  position: absolute;
} */

.stick-right {
  margin-left: 50%;
}

strong {
  color: #306399;
}

.profile-img {
  position: absolute;
  z-index: 5;
  box-shadow: 0 1px 2px 1px #00000014;
}

@media screen and (min-width: 1200px) {
  div.bpic {
    width: 360px;
    height: 360px;
  }
}
@media screen and (max-width: 1199px) {
  div.bpic {
    width: 320px;
    height: 320px;
  }
}
@media screen and (max-width: 992px) {
  div.bpic {
    width: 240px;
    height: 240px;
    margin-top: 20%;
  }
}
@media screen and (max-width: 768px) {
  div.bpic {
    width: 140px;
    height: 140px;
    margin-top: 25%;
  }
}
